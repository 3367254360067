<script setup lang="ts">
import CloseIcon from '@assets/icons/close.svg';
import { ref } from 'vue';

const isOpen = ref(false);

const toggleMenu = () => {
  isOpen.value = !isOpen.value;
};
const siteHandle = window.siteHandle;
</script>

<template>
  <div class="base-menu relative">
    <Transition name="fade">
      <div
        v-if="isOpen"
        v-click-outside="toggleMenu"
        class="base-menu__container soft-shadow fixed inset-0 z-20 flex w-full flex-col bg-purple px-20 py-60 lg:left-auto lg:w-[390px] gap-60"
      >
        <div class="flex items-center px-10 lg:px-0">
          <button
            class="ml-auto text-white hover:text-charcoal"
            type="button"
            @click="toggleMenu"
          >
            <CloseIcon class="h-[26px] w-[26px]" />
          </button>
        </div>

        <nav class="nav-scroll overflow-y-auto">
          <slot />
        </nav>
      </div>
    </Transition>

    <button
      class="flex items-center justify-center text-purple hover:text-deep-blue"
      type="button"
      @click="toggleMenu"
    >
      <slot name="icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.nav-scroll {
  @include up('lg') {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.nav-scroll::-webkit-scrollbar {
  @include up('lg') {
    width: 0;
    height: 0;
  }
}
</style>
