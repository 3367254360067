<script setup lang="ts">
import SelectChevron from '@assets/icons/select-chevron.svg?component';
import { toRefs } from 'vue';
import Multiselect from 'vue-multiselect';

type Props = {
  size?: 'sm' | 'md'
}

const props = withDefaults(defineProps<Props>(), { size: 'md' });
const { size } = toRefs(props);
</script>

<template>
  <Multiselect
    :class="['base-select', size === 'sm' && 'base-select--sm']"
    :show-labels="false"
    :allow-empty="false"
    :searchable="false"
  >
    <template #caret>
      <div class="base-select__chevron">
        <SelectChevron />
      </div>
    </template>
  </Multiselect>
</template>

<style>
@import 'vue-multiselect/dist/vue-multiselect.css';
</style>

<style lang="scss" scoped>
.base-select {
  position: relative;
  flex-grow: 1;
  width: auto;
  border-radius: 20px;
}

.base-select__chevron {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0 20px;
  color: theme('colors.charcoal');
}

:deep(.multiselect__tags) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: auto;
  height: 40px;
  padding: 0 64px 0 16px;
  outline: 1px solid transparent;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  background-color: theme('colors.white');
  transition: outline-color 0.3s;

  &:hover {
    outline-color: theme('colors.purple');
  }
}

:deep(.multiselect__content-wrapper) {
  top: 100%;
  border: 0;
  border-radius: 0 0 20px 20px;
  background-color: theme('colors.white');

  .multiselect--above & {
    top: auto;
    bottom: 100%;
    border-radius: 20px 20px 0 0;
  }
}

.multiselect--active {
  :deep(.multiselect__tags) {
    outline: 1px solid transparent;
  }
  :deep(.multiselect__content-wrapper) {
    box-shadow: $form-shadow;
  }
  :deep(.multiselect__placeholder) {
    display: block;
  }
}

:deep(.multiselect__placeholder) {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  color: theme('colors.charcoal');
}

:deep(.multiselect__input),
:deep(.multiselect__single) {
  margin: 0;
  padding: 0;
  min-height: auto;
  font-size: 16px;
  color: theme('colors.mid-grey');
  line-height: unset;
  background-color: initial;
}

:deep(.multiselect__single) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:deep(.multiselect__content) {
  padding: 8px 0;
}

:deep(.multiselect__option) {
  min-height: auto;
  padding: 8px 16px;
  font-size: 16px;
  line-height: unset;
  white-space: normal;
  transition-property: background-color, color;
  transition-duration: 0.3s;
}

:deep(.multiselect__option--highlight) {
  background-color: initial;
  color: theme('colors.purple');
}

:deep(.multiselect__option--selected) {
  background-color: theme('colors.purple') !important;
  color: theme('colors.white') !important;
}

:deep(.multiselect__tags-wrap) {
  display: flex;
  gap: 10px;
  margin-left: -20px;
  padding: 0 5px;
}

:deep(.multiselect__tag) {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  margin: 0;
  padding: 0 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  background-color: theme('colors.purple');
}

:deep(.multiselect__tag-icon) {
  position: static;
  width: auto;
  margin-left: 0;

  &::after {
    display: block;
    font-size: 26px;
    font-weight: normal;
    color: inherit;
  }
}

.base-select--sm {
  min-height: 35px;
}

.base-select--sm:deep(.multiselect__input),
.base-select--sm:deep(.multiselect__single) {
  font-size: 14px;
}

.base-select--sm:deep(.multiselect__tags) {
  height: 35px;
}
.base-select--sm:deep(.base-select__chevron) {
  height: 35px;
}

.base-select--sm:deep(.multiselect__placeholder) {
  font-size: 14px;
}

.base-select--lg:deep(.multiselect__input),
.base-select--lg:deep(.multiselect__single) {
  font-size: 24px;
}

.base-select--lg:deep(.multiselect__option) {
  font-size: 24px;
  font-weight: normal;
}

</style>
